import { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { configsMain_req, getCountryLang_req } from "../api/api";
import MainLayout from "../components/layouts/main/main.layout";
import localeEnum from "../enums/locale.enum";
import { changeCountry, changeLocale } from "../redux/global.slice";
import { mainBlockData } from "../redux/main.slice";

function getLocaleString(locale) {
    switch (locale) {
        case 0:
            return "en";
        case 1:
            return "am";
        case 2:
            return "ru";
        case 3:
            return "ge";
        default:
            return "en";
    }
}
function AppContent({ Component, pageProps }) {
    const dispatch = useDispatch();
    const [hostName, setHostName] = useState(null);
    const locale = useSelector((state) => state.global.locale);

    const windowHost = async () => {
        const data = await window.location.hostname;
        const dataLog = await window.location;

        setHostName(data);
    };
    useEffect(() => {
        windowHost();
    }, []);
    useEffect(() => {
        hostData();
    }, [hostName]);

    const hostData = () => {
        if (locale === -1) {
            if (hostName === "handle.ge") {
                dispatch(changeLocale(3));
                localStorage.setItem("lang", 3);
            }
            if (hostName === "handle-brand.com") {
                dispatch(changeLocale(0));
                localStorage.setItem("lang", 0);
            }
            if (hostName === "handle-brand.ru") {
                dispatch(changeLocale(2));
                localStorage.setItem("lang", 2);
            }
            if (hostName === "handle.am") {
                dispatch(changeLocale(1));
                localStorage.setItem("lang", 1);
            }
        }
    };
    const configDataReq = async () => {
        const configData = await configsMain_req();

        dispatch(mainBlockData(configData));
        // setConfigState(configData);
    };
    useEffect(() => {
        configDataReq();
    }, [locale]);

    const localeString = getLocaleString(locale);

    const getCountry = async () => {
        const getCountryData = await getCountryLang_req();
        dispatch(changeCountry(getCountryData.data.country));
    };

    const changeLoc = async () => {
        const data = await localStorage.getItem("lang");

        if (data == 0) {
            dispatch(changeLocale(localeEnum.EN));
        }
        if (data == 1) {
            dispatch(changeLocale(localeEnum.AM));
        }
        if (data == 2) {
            dispatch(changeLocale(localeEnum.RU));
        }
        if (data == 3) {
            dispatch(changeLocale(localeEnum.GE));
        }
    };
    useEffect(() => {
        changeLoc();
    }, [locale]);

    useEffect(() => {
        getCountry();
    }, []);
    return (
        <IntlProvider
            locale={localeString}
            messages={require(`../localization/${localeString}.json`)}
        >
            <MainLayout>
                <Component {...pageProps} />
            </MainLayout>
        </IntlProvider>
    );
}
AppContent.getServerSideProps = (context) => ({
    props: {},
});
export default AppContent;
